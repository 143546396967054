<template>
  <section>
    <div class="category">
      <div class="loading py-5" v-if="loading">
        <Loading />
      </div>
      <div v-else>
        <h3>Select Report</h3>
      </div>
      <div class="row category__tile-container">
        <div class="col-4" v-for="report in qsReports" :key="report.name">
          <div class="category__tile">
            <a :href="report.path" target="_blank">
              <h4>{{ report.name }}</h4>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { API, Auth } from "aws-amplify";
import Loading from "@/components/common/Loading";
import { getQuickSightDashboards, getUploadToBbUrlAndAuthKey } from "@/graphql/queries";
import { fetchGameFitPermission } from "../../graphql/queries";
import { mapGetters } from "vuex";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: true,
      qsReports: [],
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    viewReport(path) {
      this.$router.push(path);
    },
    async checkgamefitPermission() {
      const { data } = await API.graphql({
        query: fetchGameFitPermission,
        variables: { email: this.user.email },
      });
      return data.fetchGameFitPermission;
    },
    async fetchQuickSightDashboardUrlsAppSync() {
      const data = await Auth.currentSession();
      const qsDashboardTmp = await API.graphql({
        query: getQuickSightDashboards,
        variables: {
          jwtToken: data.idToken.jwtToken,
          payloadSub: data.idToken.payload.sub,
          email: data.idToken.payload.email,
          username: data.idToken.payload["cognito:username"],
        },
      });
      const gamefitPermission = await this.checkgamefitPermission();
      const dashboardData = qsDashboardTmp.data.getQuickSightDashboards;
      for (let i = 0; i < dashboardData.length; i++) {
        let repObj = { name: "", path: "" };
        repObj["name"] = dashboardData[i].name;
        repObj["path"] = dashboardData[i].url;
        this.qsReports.push(repObj);
      }

      const gamefitUrl = await API.graphql({
        query: getUploadToBbUrlAndAuthKey,
        variables: {
          application: "ASSORTMENT_PLANNING_TOOL",
          module: "PLANNING",
          sub_module: "GAME_FIT",
        },
      });
      let url = gamefitUrl.data.getUploadToBbUrlAndAuthKey[0].value_;

      if (gamefitPermission) {
        const repObj = {
          name: "GameFit Email Download",
          path: `${url}${data.idToken.jwtToken}`,
        };
        this.qsReports.push(repObj);
      }
      this.loading = false;
    },
  },

  mounted() {
    this.fetchQuickSightDashboardUrlsAppSync();
  },
};
</script>
<style scoped lang="scss">
.category__tile {
  height: 125px;
  a {
    color: #fff;
    text-decoration: none;
    text-align: right;
    width: 100%;
    text-transform: capitalize;
  }
}
</style>
